// extracted by mini-css-extract-plugin
export var header = "W_N";
export var item = "W_cg";
export var itemImage = "W_cD";
export var itemIsExternal = "W_cS";
export var itemIsInView = "W_cQ";
export var itemIsLinked = "W_cR";
export var itemText = "W_cF";
export var itemTime = "W_cT";
export var itemTimeIsBig = "W_cV";
export var logo = "W_bP";
export var root = "W_b";
// extracted by mini-css-extract-plugin
export var container = "D_bD";
export var containerIsSticky = "D_bY";
export var fadeInDown = "D_bZ";
export var hamburger = "D_cg";
export var hamburgerIsOpened = "D_ch";
export var logo = "D_bG";
export var logoIsSticky = "D_b0 D_bG";
export var mainNav = "D_b5";
export var mainNavIsSticky = "D_b6 D_b5";
export var mainNavL18nSwitch = "D_b7";
export var mainNavList = "D_b8";
export var mainNavListIsOpened = "D_b9";
export var mainNavListItem = "D_cb";
export var mainNavListItemIsActive = "D_cc";
export var mainNavListItemIsMobile = "D_cf";
export var mainNavListItemIsSmall = "D_cd";
export var subNav = "D_b1";
export var subNavItem = "D_b2";
export var subNavItemIsActive = "D_b3";
export var subNavL18nSwitch = "D_b4";